import type { Dispatch, SetStateAction } from 'react';
import { useState, useRef, useCallback } from 'react';

export function useRefState<T>(
  initialValue: T,
): [T, Dispatch<SetStateAction<T>>, React.MutableRefObject<T>] {
  const [state, setState] = useState(initialValue);
  const ref = useRef(state);

  const setRefState = useCallback((value: SetStateAction<T>) => {
    setState((prevState) => {
      const newValue =
        typeof value === 'function' ? (value as (prevState: T) => T)(prevState) : value;
      ref.current = newValue;
      return newValue;
    });
  }, []);

  return [state, setRefState, ref];
}
