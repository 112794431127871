import React from 'react';
import { Modal } from 'antd';
import Image from 'next/image';
import styles from './index.module.less';

interface GlobalWarningModalProps {
  visible: boolean;
  title: string;
  desc?: string;
  onClick: () => void;
}

const GlobalWarningModal: React.FC<GlobalWarningModalProps> = (props) => {
  const { visible, title, desc, onClick } = props;

  return (
    <div>
      <Modal
        className={styles.confirmModal}
        centered
        visible={visible}
        footer={null}
        closable={false}
        width={'420px'}
      >
        <div className={styles.title}>
          <Image width={24} height={24} objectFit="cover" src="/icons/warning.svg" alt="" />
          <span>{title}</span>
        </div>
        {desc && <div className={styles.desc}>{desc}</div>}
        <div className={styles.btnWrapper}>
          <span className={styles.btnConfirm} onClick={onClick}>
            <span>刷新页面</span>
          </span>
        </div>
      </Modal>
    </div>
  );
};

export default GlobalWarningModal;

export const showGlobalWarningModal = ({
  title,
  desc,
  onClick,
  onClose,
}: {
  title: string;
  desc?: string;
  onClick: () => void;
  onClose?: () => void;
}) => {
  return Modal.confirm({
    className: styles.confirmModal,
    centered: true,
    closable: false,
    icon: null,
    content: (
      <>
        <div className={styles.title}>
          <Image width={24} height={24} objectFit="cover" src="/icons/warning.svg" alt="" />
          <span>{title}</span>
        </div>
        {desc && <div className={styles.desc}>{desc}</div>}
        <div className={styles.btnWrapper}>
          <span className={styles.btnConfirm} onClick={onClick}>
            <span>刷新页面</span>
          </span>
        </div>
      </>
    ),
    onCancel: onClose,
  });
};
