/**
 * Content-Security-Policy
 */

import { isProdMode } from './env';

const policy = [
  "'self'",
  "'unsafe-inline'",
  "'unsafe-eval'",
  'data:',
  'mediastream:',
  'blob:',
  'filesystem:',
  '*.textin.com',
  '*.baidu.com',
  '*.intsig.net',
  'wss://*.intsig.net',
  '*.intsig.com',
  '*.qixin.com',
  '*.ccint.com',
  '*.sensorsdata.cn',
  '*.bcebos.com',
  '*.giocdn.com',
  '*.growingio.com',
  '*.geetest.com',
  '*.geevisit.com',
  '*.qbox.me',
  '*.bdimg.com',
  '*.bdstatic.com',
  // 'cdn.jsdelivr.net',
  '*.npsmeter.cn',
  // 资讯中心图片
  '*.qpic.cn',
  '*.aliyuncs.com',
  // pdf_to_markdown切图
  '*.myqcloud.com',
  // 'openapi.alipay.com',
  '*.alipay.com',
  !isProdMode && 'http://localhost:*',
].filter(Boolean);

export default policy;
