import type { FC } from 'react';
import { Row } from 'antd';
import dayjs from 'dayjs';
import Image from 'next/image';
import WechatCode from './assets/wechatCode.png';
import styles from './WechatMobile.module.scss';
import { getLocalUserInfo } from '@/utils';
import { track } from '@/utils/track';
import HeaderMobile from './HeaderMobile';
import { useRouter } from 'next/router';

const WechatMobile: FC<{ onClose?: () => void }> = ({ onClose }) => {
  const router = useRouter();

  const handleClose = () => {
    onClose?.();
    try {
      const redirectUrl = decodeURIComponent(router.query.redirect as string);
      window.open(redirectUrl, '_self');
    } catch (error) {
      router.replace('/');
    }
  };
  return (
    <div className={styles.container}>
      <HeaderMobile />
      <div className={styles.title}>欢迎来到 TextIn 智能文档平台</div>
      <div className={styles.desc}>添加TextIn文本智能福利官，领取超多福利</div>
      <div className={styles.content}>
        <div className={styles.qrcodeArea}>
          <Image src={WechatCode} width={150} height={150} alt="" />
          <div className={styles.qrcodeText1}>添加Textin福利官</div>
          <div className={styles.qrcodeText2}>为您激活加赠额度</div>
        </div>
        <div className={styles.giftArea}>
          <Row className={styles.giftItem} align="middle">
            <div className={styles.giftLabel}>首次添加享</div>
            <div className={styles.giftContent}>
              <span>加赠免费额度</span>
              <span className={styles.giftTextStrong}>1000次/页</span>
            </div>
          </Row>
          <Row className={styles.giftItem} align="middle">
            <div className={styles.giftLabel}>首次充值享</div>
            <div className={styles.giftContent}>
              <span>首次充值 (100元以上)</span>
              <span className={styles.giftTextStrong}>送100页</span>
            </div>
          </Row>
          <Row className={styles.giftItem} align="middle">
            <div className={styles.giftLabel}>入群领券包</div>
            <div className={styles.giftContent}>
              <span>每周可领超多品类</span>
              <span className={styles.giftTextStrong}>8折券</span>
            </div>
          </Row>
        </div>
      </div>
      <Row className={styles.footer} align="middle">
        <div className={styles.closeText} onClick={handleClose}>
          稍后添加
        </div>
        <div className={styles.closeText} onClick={handleClose}>
          关闭
        </div>
      </Row>
    </div>
  );
};

const showLeadsWechatModalCountCacheKey = () =>
  `showLeadsWechatModalCount_${getLocalUserInfo()?.account || ''}`;
const lastTimeShowLeadsWechatModalCacheKey = () =>
  `lastTimeShowLeadsWechatModal_${getLocalUserInfo()?.account || ''}`;

export const showWechatMobileModal = ({
  force,
  redirect,
  onClose,
}: { force?: boolean; redirect?: string; onClose?: () => void } = {}) => {
  const hasShownCount = localStorage.getItem(showLeadsWechatModalCountCacheKey()) || 0;
  const lastShowModalTime = localStorage.getItem(lastTimeShowLeadsWechatModalCacheKey());
  if (!force) {
    if (
      (hasShownCount && Number(hasShownCount) >= 3) ||
      dayjs().diff(dayjs(lastShowModalTime), 'day') < 1
    ) {
      onClose?.();
      return false;
    }

    localStorage.setItem(lastTimeShowLeadsWechatModalCacheKey(), dayjs().toJSON());
    localStorage.setItem(
      showLeadsWechatModalCountCacheKey(),
      (Number(hasShownCount || 0) + 1).toString(),
    );
  }
  track({
    keyword: '线索留存【二维码弹窗】（曝光）',
    url: window.location.href,
    trigger: force ? '右侧悬浮窗' : '登录流程',
    device: 'mo',
  });

  window.open(`/user/portraits/wechat?redirect=${redirect}`, '_self');
  return true;
};

export default WechatMobile;
