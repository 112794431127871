import { useEffect, useRef, useState } from 'react';
import { createContainer } from 'unstated-next';
import { compareVersions, getLocalAppVersion } from '@/utils/version';
import { getSettingService } from '@/service/setting';
import { showGlobalWarningModal } from '@/components/GlobalWarningModal';
import { useRefState } from '@/utils/hooks/useRefState';

export interface IVersionType {
  name: string;
  key: string;
  versionListKey: string;
  currentVersionKey: string;
  value?: string;
}

export type IVersionConfig = {
  id?: string;
  version: string;
  is_force?: boolean;
  creator?: string;
  create_time?: number;
  updator?: string;
  update_time?: number;
};

const currentVersionKey = 'textin_com_current_version';

function useStore() {
  const localVersion = getLocalAppVersion();
  const isModalOpenRef = useRef<boolean>(false);
  const [remoteVersion, setRemoteVersion, remoteVersionRef] = useRefState<IVersionConfig | null>(
    null,
  );
  const [checkingVersion, setCheckingVersion] = useState(false);

  const loadRemoteVersion = async () => {
    try {
      const res = await getSettingService(currentVersionKey);
      if (res.code === 200) {
        setRemoteVersion(JSON.parse(res.data.value));
      } else {
        console.error(res.msg);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const checkVersion = async () => {
    try {
      if (checkingVersion || document.visibilityState !== 'visible' || isModalOpenRef.current) {
        return;
      }
      setCheckingVersion(true);
      await loadRemoteVersion();
      const remoteVersion = remoteVersionRef.current;
      if (!remoteVersion?.version || !localVersion) {
        return;
      }
      if (compareVersions(remoteVersion.version, localVersion) > 0 && remoteVersion.is_force) {
        showGlobalWarningModal({
          title: '版本发生更新',
          desc: '页面资源已过期，当前版本不兼容最新服务，请刷新页面后使用。',
          onClick: () => {
            window.location.reload();
          },
          onClose: () => {
            isModalOpenRef.current = false;
          },
        });
        isModalOpenRef.current = true;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setCheckingVersion(false);
    }
  };

  useEffect(() => {
    console.log(
      `%cApp Version: ${localVersion}`,
      'font-size: 24px; font-weight: bold; color: #1a66ff;',
    );
  }, [localVersion]);

  useEffect(() => {
    checkVersion();
    window.addEventListener('focus', checkVersion);
    document.addEventListener('visibilitychange', checkVersion);

    return () => {
      window.removeEventListener('focus', checkVersion);
      document.removeEventListener('visibilitychange', checkVersion);
    };
  }, []);

  return {
    remoteVersion,
    checkVersion,
  };
}

export const versionModelContainer = createContainer(useStore);
