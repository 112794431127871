export function isValidVersion(version: string): boolean {
  const versionRegex = /^\d+(\.\d+){1,3}$/;
  return versionRegex.test(version);
}

export function compareVersions(version1: string, version2: string): number {
  if (!isValidVersion(version1) || !isValidVersion(version2)) {
    throw new Error('参数错误');
  }
  const splitVersion = (version: string) => version.split('.').map(Number);

  const v1Parts = splitVersion(version1);
  const v2Parts = splitVersion(version2);

  const maxLength = Math.max(v1Parts.length, v2Parts.length);

  for (let i = 0; i < maxLength; i++) {
    const v1 = v1Parts[i] || 0;
    const v2 = v2Parts[i] || 0;

    if (v1 > v2) {
      return 1;
    }
    if (v1 < v2) {
      return -1;
    }
  }

  return 0;
}

export const getLocalAppVersion = () => process.env.NEXT_PUBLIC_APP_VERSION;
